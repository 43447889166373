import styled from "styled-components";
import bg from "../../../assets/image/home/hero/bg.png";

export const StyledOutro = styled.div`
  background-image: url(${bg});
  display: flex;
  align-items: center;
  background-position: center center;
  background-size: cover;
  justify-content: center;
  position: relative;
  padding: 50px 0px;
`;

export const Logo = styled.img`
  height: 100px;
`;

export const Content = styled.div`
  text-align: center;
`;

export const Copy = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 50px;
`;

export const Homepage = styled.a`
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-decoration: none;
`;
