import styled from "styled-components";
import bg from "../../../assets/image/home/hero/bg.png";

export const StyledHero = styled.div`
  background-image: url(${bg});
  height: 80vh;
  display: flex;
  align-items: center;
  background-position: center center;
  background-size: cover;
  justify-content: center;
  position: relative;
`;

export const Logo = styled.img`
  height: 300px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 200px;
  }
`;

export const Content = styled.div`
  text-align: center;
`;

export const Headlines = styled.div`
  margin-top: 25px;
`;
export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Sublines = styled.div`
  margin-top: 25px;
`;
export const Subline = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Creator = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  width: 100%;
  position: absolute;
  bottom: 25px;
  font-size: ${({ theme }) => theme.font.size.sm};
`;
