import { Container } from "@think-internet/ui-components";
import {
  StyledHero,
  Logo,
  Content,
  Headlines,
  Headline,
  Sublines,
  Subline,
  Creator,
} from "./Hero.Styled";
import logo from "../../../assets/image/home/hero/logo.png";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledHero>
      <Content>
        <Logo src={logo} />
        <Container>
          <Headlines>
            {translation.home.hero.headlines.map((h) => {
              return <Headline key={getUUID()}>{h}</Headline>;
            })}
          </Headlines>
          <Sublines>
            {translation.home.hero.sublines.map((h) => {
              return <Subline key={getUUID()}>{h}</Subline>;
            })}
          </Sublines>
        </Container>
      </Content>
      <Creator>{translation.home.hero.creator}</Creator>
    </StyledHero>
  );
};

export default Hero;
