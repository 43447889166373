import styled from "styled-components";

export const StyledUsage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: 100px 0;
`;

export const Items = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;
export const Item = styled.div`
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  position: relative;
  padding: 75px 10px 25px 10px;
  border: thin solid ${({ theme }) => theme.color.border.primary};
`;

export const Image = styled.img`
  height: 100px;
  position: absolute;
  background-color: ${({ theme }) => theme.color.background.primary};
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
`;

export const Title = styled.div`
  font-family: "bold";
  text-align: center;
  margin-top: 15px;
`;
export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
`;
export const Text = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  &:last-child {
    font-family: "bold";
  }
`;

export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.font.size.xlg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
