import { useSelector } from "react-redux";
import { StyledFooter, Flex, Column, CustomLink } from "./Footer.Styled";
import props from "../../../redux/props";

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledFooter>
      <Flex>
        <Column>
          <CustomLink
            newTab
            href="https://www.blackflagship.com/impressum"
            text={translation.imprint.headline}
          />
        </Column>
        <Column>
          <CustomLink
            newTab
            href="https://www.blackflagship.com/datenschutz"
            text={translation.dataProtection.headline}
          />
        </Column>
      </Flex>
    </StyledFooter>
  );
};

export default Footer;
