import { Container } from "@think-internet/ui-components";
import {
  Headline,
  StyledUsage,
  Items,
  Item,
  Image,
  Title,
  Texts,
  Text,
} from "./Usage.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import business from "../../../assets/image/home/usage/business.svg";
import social from "../../../assets/image/home/usage/social.png";
import presentation from "../../../assets/image/home/usage/presentation.svg";

const Usage = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const images = [business, social, presentation];
  return (
    <StyledUsage>
      <Container>
        <Headline>{translation.home.usage.headline}</Headline>
        <Items>
          {translation.home.usage.items.map((item, i) => (
            <Item key={getUUID()}>
              <Image src={images[i]} />
              <Title>{item.title}</Title>
              <Texts>
                {item.texts.map((text) => (
                  <Text key={getUUID()}>{text}</Text>
                ))}
              </Texts>
            </Item>
          ))}
        </Items>
      </Container>
    </StyledUsage>
  );
};

export default Usage;
