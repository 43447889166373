import { Container } from "@think-internet/ui-components";
import {
  StyledHappening,
  Headline,
  Split,
  Page,
  Title,
  Items,
  Item,
  Icon,
  Content,
  ItemTitle,
  ItemText,
  Splitter,
  SplitterImage,
} from "./Happening.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import splitter from "../../../assets/image/home/happening/splitter.svg";

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledHappening>
      <Container>
        <Headline>{translation.home.happening.headline}</Headline>
        <Split>
          <Splitter>
            <SplitterImage src={splitter} />
          </Splitter>
          {translation.home.happening.pages.map((p, i) => (
            <Page key={getUUID()}>
              <Title invert={i % 2 === 1}>{p.title}</Title>
              <Items>
                {p.items.map((i) => (
                  <Item key={getUUID()}>
                    <Icon />
                    <Content>
                      <ItemTitle>{i.title}</ItemTitle>
                      <ItemText>{i.text}</ItemText>
                    </Content>
                  </Item>
                ))}
              </Items>
            </Page>
          ))}
        </Split>
      </Container>
    </StyledHappening>
  );
};

export default Hero;
