import { Container } from "@think-internet/ui-components";
import {
  Headline,
  StyledAdvantages,
  Items,
  Item,
  Image,
  Title,
  Text,
} from "./Advantages.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import { ImageContainer } from "./Advantages.Styled";
import checklist from "../../../assets/image/home/advantages/checklist.svg";
import download from "../../../assets/image/home/advantages/download.png";
import loop from "../../../assets/image/home/advantages/loop.svg";
import scale from "../../../assets/image/home/advantages/scale.svg";
import stairs from "../../../assets/image/home/advantages/stairs.svg";

const Advantages = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const images = [checklist, scale, stairs, loop, download];
  return (
    <StyledAdvantages>
      <Container>
        <Headline>{translation.home.advantages.headline}</Headline>
        <Items>
          {translation.home.advantages.items.map((item, i) => (
            <Item key={getUUID()}>
              <ImageContainer>
                <Image src={images[i]} />
              </ImageContainer>
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </Item>
          ))}
        </Items>
      </Container>
    </StyledAdvantages>
  );
};

export default Advantages;
