import styled from "styled-components";
import titleBG1 from "../../../assets/image/home/happening/title-bg-1.svg";
import titleBG2 from "../../../assets/image/home/happening/title-bg-2.svg";
import arrow from "../../../assets/image/home/happening/arrow.png";

export const StyledHappening = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 100px 0;
`;

export const Split = styled.div`
  display: flex;
  justify-content: center;
  gap: 150px;
  margin-top: 50px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Page = styled.div`
  width: 100%;
`;

export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.font.size.xlg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

export const Title = styled.div`
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  background-image: url(${titleBG1});
  ${({ invert }) => invert && `background-image: url(${titleBG2});`}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 7.5px 10px 5px 10px;
  display: block;
  border-radius: 30px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
`;
export const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
export const Icon = styled.div`
  width: 25px;
  background-image: url(${arrow});
  background-size: 10px;
  background-repeat: no-repeat;
`;
export const Content = styled.div`
  width: 100%;
`;
export const ItemTitle = styled.div`
  font-family: "bold";
  font-size: ${({ theme }) => theme.font.size.sm};
`;
export const ItemText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const Splitter = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: calc(100% - 50px);
  background-color: ${({ theme }) => theme.color.background.secondary};
  overflow: visible;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    left: 0px;
    top: 47.5%;
    transform: translateY(50%);
    width: 100%;
    height: 1px;
  }
`;

export const SplitterImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  width: 40px;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 15px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    transform: rotate(90deg) translate(-100%, 20%);
  }
`;
