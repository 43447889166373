import styled from "styled-components";

export const StyledPackages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 100px 0;
`;

export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.font.size.xlg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

export const Split = styled.div`
  display: flex;
  justify-content: center;
  gap: 75px;
  margin-top: 50px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const Package = styled.a`
  display: block;
  border: thin solid ${({ theme }) => theme.color.border.primary};
  border-radius: ${({ theme }) => theme.border.radius};
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.background.primary};
  text-decoration: none;
`;

export const Title = styled.div`
  font-family: "bold";
  text-align: center;
  padding: 15px 0px;
  color: ${({ theme }) => theme.color.font.secondary};
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center center;
`;

export const PriceSection = styled.div`
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 15px 15px;
`;
export const Price = styled.div`
  font-family: "bold";
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
`;
export const Amount = styled.div`
  font-family: "bold";
  font-size: 12px;
  text-align: center;
`;
export const PriceHint = styled.div`
  text-align: center;
  font-size: 12px;
`;
export const Texts = styled.div`
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;

export const Checkmark = styled.img`
  width: 15px;
  object-fit: contain;
`;
export const TotalPriceSection = styled.div`
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 15px 15px;
`;
export const Discount = styled.div`
  font-family: "bold";
  text-align: center;
`;
export const TotalLabel = styled.div`
  font-family: "bold";
  font-size: 12px;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const TotalPrice = styled.div`
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center center;
  border-radius: ${({ theme }) => theme.border.radius};
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: "bold";
  text-align: center;
  padding: 17.5px 0px 15px 0px;
  margin-top: 10px;
`;
