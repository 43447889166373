import { Container } from "@think-internet/ui-components";
import {
  Headline,
  Package,
  Split,
  StyledPackages,
  Title,
  PriceSection,
  Price,
  Amount,
  PriceHint,
  Texts,
  Text,
  Checkmark,
  TotalPriceSection,
  Discount,
  TotalPrice,
  TotalLabel,
} from "./Packages.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import titleBG from "../../../assets/image/home/packages/title-bg.svg";
import checkmark from "../../../assets/image/home/packages/checkmark.png";

const Packages = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const bgs = [titleBG, titleBG, titleBG];

  return (
    <StyledPackages>
      <Container>
        <Headline>{translation.home.packages.headline}</Headline>
        <Split>
          {translation.home.packages.items.map((item, i) => (
            <Package
              key={getUUID()}
              href={translation.home.packages.href}
              target="_blank"
            >
              <Title bg={bgs[i]}>{item.title}</Title>
              <PriceSection>
                <Price>{item.singlePrice}</Price>
                <Amount>{item.amount}</Amount>
                <PriceHint>{translation.home.packages.priceHint}</PriceHint>
              </PriceSection>
              <Texts>
                {item.texts.map((t) => (
                  <Text key={getUUID()}>
                    <Checkmark src={checkmark} />
                    {t}
                  </Text>
                ))}
              </Texts>
              <TotalPriceSection>
                {item.discount && <Discount>{item.discount}</Discount>}
                {!item.discount && <Discount>&nbsp;</Discount>}
                <TotalPrice bg={bgs[i]}>
                  <TotalLabel>
                    {translation.home.packages.totalLabel}
                  </TotalLabel>
                  {item.totalPrice}
                </TotalPrice>
              </TotalPriceSection>
            </Package>
          ))}
        </Split>
      </Container>
    </StyledPackages>
  );
};

export default Packages;
