import styled from "styled-components";

export const StyledAdvantages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  padding: 100px 0;
`;

export const Items = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;
export const Item = styled.div`
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
`;

export const ImageContainer = styled.div`
  height: 125px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-height: 100px;
  max-width: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    max-height: 75px;
    max-width: 75px;
  }
`;

export const Title = styled.div`
  font-family: "bold";
  text-align: center;
  margin-top: 15px;
`;
export const Text = styled.div`
  text-align: center;
  margin-top: 15px;
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.font.size.xlg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
