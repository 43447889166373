import { Container } from "@think-internet/ui-components";
import {
  Block,
  Split,
  StyledCTA,
  Headline,
  Text,
  Icon,
  HeadlineSplit,
  Content,
  SubHeadline,
} from "./CTA.Styled";
import { getUUID } from "../../../utility";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import cart from "../../../assets/image/cta/cart.svg";
import phone from "../../../assets/image/cta/phone.svg";

const CTA = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const blockImages = [phone, cart];
  return (
    <StyledCTA>
      <Container>
        <Split>
          {translation.cta.blocks.map((b, i) => (
            <Block key={getUUID()}>
              <Content href={b.href} target="_blank">
                <HeadlineSplit>
                  <Headline>{b.headline}</Headline>
                  <Icon src={blockImages[i]} />
                </HeadlineSplit>
                <Text>{b.text}</Text>
              </Content>
              <Content href={translation.cta.subBlocks[i].href} target="_blank">
                <SubHeadline>
                  {translation.cta.subBlocks[i].headline}
                </SubHeadline>
              </Content>
            </Block>
          ))}
        </Split>
      </Container>
    </StyledCTA>
  );
};

export default CTA;
