import styled from "styled-components";
import bg from "../../../assets/image/home/looks/bg.png";

export const StyledLooks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 100px 0;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const Items = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    gap: 25px;
  }
`;
export const Item = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 15px);
  }
`;

export const Image = styled.img`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
