//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    hero: {
      headlines: [
        "KUNDEN-ERFOLGSGESCHICHTEN SIND KEIN TREND.",
        "IMMER SCHON NUTZEN WIR PLAKATIVE BEISPIELE, UM DAS WAS WIR TUN, EINFACH ZU BESCHREIBEN.",
      ],
      sublines: [
        "Die besten Beispiele sind natürlich die, bei denen wir gemeinsam mit unseren Kunden heiße Eisen aus dem Feuer holen.",
        "Sie heißen Kunden Erfolgsgeschichten (Customer Success Stories), wir nennen Sie Anchor Story",
        "- weil sie sich in den Köpfen deiner Kunden verankert.",
      ],
      creator: "Ein Product von BLACKFLAGSHIP ®",
    },
    overview: {
      items: [
        {
          title: "CUSTOMER-SUCCESS-STORIES",
          text: "Durch klar und transparent formulierte Beispiele wird dein Angebot griffiger, deutlicher und professioneller.",
        },
        {
          title: "REFERENZ STATT EIGENLOB",
          text: "Deine Customer Success Story ist immer eine Referenz und Empfehlung für die Qualität der Zusammenarbeit von deinen Kunden für neue Kunden.",
        },
        {
          title: "KUNDENPERSPEKTIVE SCHAFFEN",
          text: "Zufriedene Kunden sollen dein Angebot in 3 Sätzen auf den Punkt bringen können.",
        },
      ],
    },
    advantages: {
      headline:
        "WELCHE VORTEILE BRINGT ANCHOR STORY ALS KUNDEN-ERFOLGSGESCHICHTE?",
      items: [
        {
          title: "INTERAKTIVE CHECKLISTE",
          text: "Du erhälst eine detaillierte Checkliste für alle Informationen, die für die Erstellung der Kunden-Erfolgsgeschichte notwendig ist. So sind alle Bestandteile an einer Stelle.",
        },
        {
          title: "EINSETZBARKEIT",
          text: "Anchor Storys kannst du auf allen sozialen Netzwerken, direkt zum Versand an Kunden per Mail oder direkt im Gespräch verwenden. Die Story ist für jede Situation bereit.",
        },
        {
          title: "LEITFADEN",
          text: "Unser Leitfaden führt dich mit den richtigen Fragen zur richtigen Story. Dein Angebot wird einfacher und prägnanter formuliert, um mehr Verständnis bei Kunden zu erlangen.",
        },
        {
          title: "WIEDERVERWENDBARKEIT",
          text: "Gute Geschichten werden nie alt. So tun es Customer-Success-Stories auch nicht. Du kannst sie in allen Varianten wieder verwenden und mit neuen Kunden/ Leads teilen.",
        },
        {
          title: "PASSENDE FORMATE",
          text: "Jede Anchor Story wird in passgenaue Dateiformate für unterschiedlichen Gebrauch exportiert. Damit kannst du sie so nutzen, wie es für dich am besten passt.",
        },
      ],
    },
    usage: {
      headline: "WIE KANN ICH ANCHOR STORY VERWENDEN?",
      items: [
        {
          title: "BUSINESS NETWORKS",
          texts: [
            "Teile Customer-Success-Stories auf den bekannten Business-Netzwerken.",
            "Mehr als ein einfacher Bild-Post über LinkedIn und Xing. Auf WhatsApp-Business kannst du die Erfolgsgeschichte als Story oder direkt an Kunden & Leads versenden.",
            "In beiden Fällen direkt angepasst auf das Smartphone-Screen-Format und Desktop-Ansicht.",
          ],
        },
        {
          title: "SOCIAL MEDIA",
          texts: [
            "Nutze die Anchor Story für Instagram, Facebook,Twitter & Co.",
            "Komplexe Produkte und Dienstleistungen einfach und spannend erklärt. Egal ob als Story, Karussell oder zum durchsliden - Klarheit erhöhen und Anwendungsbeispiele aufzeigen.",
            "Für alle Plattformen direkt angepasst auf das Smartphone-Screen-Format.",
          ],
        },
        {
          title: "PRÄSENTATIONEN",
          texts: [
            "Als Präsentation im Gespräch, zum eMail-Versand oder auf der Website.",
            "Du kannst die Vorteile einer Zusammenarbeit je Story an einem Beispiel fokussieren. Relevante Szenarien oder ähnliche Kundenstruktur schafft notwendiges Vertrauen in kurzer Zeit.",
            "Alle Einsatzmöglichkeiten werden direkt angepasst für die Desktop-Ansicht.",
          ],
        },
      ],
    },
    looks: {
      headline: "WIE KANN EINE ANCHOR STORY AUSSEHEN?",
    },
    happening: {
      headline: "WAS PASSIERT KONKRET?",
      pages: [
        {
          title: "WAS WIR VON DIR BENÖTIGEN...",
          items: [
            {
              title: "... 30 MINUTEN DEINER ZEIT",
              text: "Damit wir dich und dein Unternehmen besser kennenlernen, vereinbaren wir ein gemeinsames Onboarding-Interview. So können wir Feinheiten und individuelle Themen mit einfließen lassen.",
            },
            {
              title: "... EINEN AUSGEFÜLLTEN FRAGEBOGEN",
              text: "Weil niemand die Geschichte hinter den Kulissen besser kennt als du, haben wir einen Fragebogen vorbereitet. Nur 7 Fragen musst du in Stichpunkten beantworten.",
            },
            {
              title: "... BILDMATERIAL,WELCHES DIE GESCHICHTEVERANSCHAULICHT",
              text: "Fotos, Bilder oder Videoausschnitte deines Produktes und/ oder der Zusammenarbeit mit dem Kunden sollen Teil der Anchor Story werden. Wenn du kein Material zur Hand hast, unterstützen wir dich dabei.",
            },
          ],
        },
        {
          title: "WAS WIR ERARBEITEN...",
          items: [
            {
              title:
                "... EINE GEMEINSAME DESIGN-GRUNDLAGE FÜR DEINE ANCHOR STORY",
              text: "Damit deine Anchor Story einen roten Faden erhält und hohe Wiedererkennung schafft, erstellen wir deine individuelle Vorlage. Das ist die Basis für alle kommenden Customer Success Stories.",
            },
            {
              title: "... DIE ANPASSUNG UND OPTIMIERUNG DEINER BILDER & TEXTE",
              text: "Dein Bildmaterial und Texte werden von unseren Designern und Textern überarbeitet. Das Zusammenspiel aus Text & Bild bringt die gesamte Story prägnant auf den Punkt.",
            },
            {
              title: "... DEINE ANCHOR STORY IN DEN PASSENDEN FORMATEN",
              text: "Fotos, Du erhältst jede Success-Story als PDF-Format (Screen & Mobile) und als JPG-Dateien. So kannst du deine Story mit wenigen Handgriffen auf allen relevanten Kanälen teilen.",
            },
          ],
        },
      ],
    },
    sequence: {
      headline: "WIE SIEHT DER ABLAUF AUS?",
    },
    packages: {
      headline: "WELCHE PAKETE GIBT ES?",
      totalLabel: "TOTAL",
      priceHint: "einmalige Abrechnung",
      href: "https://anchorstory.shop.copecart.com",
      items: [
        {
          title: "3ER PAKET",
          singlePrice: "400 €",
          amount: "pro Anchor Story / 3 Stories",
          texts: [
            "3 Stories",
            "Optimierung der Bilder",
            "Überarbeitung der Texte",
            "Checkliste & Fragebogen",
            "Onboarding-Interview",
            "490 € Story Template",
            " 1 x Korrektur-Loop",
          ],
          totalPrice: "1690 €",
        },
        {
          title: "5ER PAKET",
          singlePrice: "350 €",
          amount: "pro Anchor Story / 5 Stories",
          texts: [
            "5 Stories",
            "Optimierung der Bilder",
            "Überarbeitung der Texte",
            "Checkliste & Fragebogen",
            "Onboarding-Interview",
            "390 € Story Template",
            "2 x Korrektur-Loop",
          ],
          discount: "24 % Rabatt",
          totalPrice: "2140 €",
        },
        {
          title: "10ER PAKET",
          singlePrice: "300 €",
          amount: "pro Anchor Story / 10 Stories",
          texts: [
            "10 Stories",
            "Optimierung der Bilder",
            "Überarbeitung der Texte",
            "Checkliste & Fragebogen",
            "Onboarding-Interview",
            "290 € Story Template",
            "3 x Korrektur-Loop",
          ],
          discount: "42 % Rabatt",
          totalPrice: "3290 €",
        },
      ],
    },
    outro: {
      homepage: "www.BLACKFLAGSHIP.com",
    },
  },

  cta: {
    blocks: [
      {
        headline: "KURZER FAQ-CALL",
        text: "Du hast noch Fragen? Vereinbare unter diesem Link einen 15-Minuten-Telefon",
        href: "https://go.oncehub.com/AnchorStoryFAQCall",
      },
      {
        headline: "DIREKT ZUM SHOP",
        text: "Buche deine Anchor Story direkt im Shop & starte deine Kundenerfolgsgeschichte",
        href: "https://anchorstory.shop.copecart.com",
      },
    ],
    subBlocks: [
      {
        headline: "Hier einen kostenfreien Telefon-termin vereinbaren",
        href: "https://go.oncehub.com/AnchorStoryFAQCall",
      },
      {
        headline: "Direkt zum Shop",
        href: "https://anchorstory.shop.copecart.com",
      },
    ],
  },

  copy: {
    cta: "Kopieren",
    success: "Kopiert",
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  generic: {
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    phone: "Telefon",
    fax: "Fax",
    mail: "E-Mail",
    password: "Passwort",
    save: "Speichern",
  },

  login: {
    cta: "Einloggen",
    error: "E-Mail Adresse oder Passwort falsch.",
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
