import styled from "styled-components";
import bg from "../../../assets/image/home/overview/bg.svg";

export const StyledOverview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 100px 0;
`;

export const Split = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Page = styled.div``;

export const IPhone = styled.img`
  height: 500px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 300px;
  }
`;

export const Items = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
export const Item = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  padding: 25px;
`;

export const Title = styled.div`
  font-family: "bold";
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
`;
export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
`;
