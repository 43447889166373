import { Container } from "@think-internet/ui-components";
import {
  StyledOverview,
  Split,
  Page,
  Items,
  Item,
  Title,
  Text,
} from "./Overview.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import iPhone from "../../../assets/image/home/overview/iPhone.png";
import { IPhone } from "./Overview.Styled";

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledOverview>
      <Container>
        <Split>
          <Page>
            <IPhone src={iPhone} />
          </Page>
          <Page>
            <Items>
              {translation.home.overview.items.map((i) => (
                <Item key={getUUID()}>
                  <Title>{i.title}</Title>
                  <Text>{i.text}</Text>
                </Item>
              ))}
            </Items>
          </Page>
        </Split>
      </Container>
    </StyledOverview>
  );
};

export default Hero;
