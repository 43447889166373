import { StyledLayout, CoreContent } from "./Layout.Styled";
import Secure from "./Secure/Secure";

const Layout = ({ children, className, authRequired = true }) => {
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired}>
        <CoreContent>{children}</CoreContent>
      </Secure>
    </StyledLayout>
  );
};

export default Layout;
