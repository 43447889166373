import styled from "styled-components";

export const StyledCTA = styled.div`
  margin: 50px 0px;
`;

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 15px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Content = styled.a`
  display: block;
  background-color: #666;
  padding: 15px;
  padding: 17.5px 15px 10px 15px;
  position: relative;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeadlineSplit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Headline = styled.div`
  font-family: "bold";
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
`;
export const SubHeadline = styled.div`
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
`;

export const Icon = styled.img`
  max-width: 35px;
  max-height: 35px;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 15px;
  text-align: left;
`;
