import { Container } from "@think-internet/ui-components";
import { Headline, StyledLooks, Items, Item, Image } from "./Looks.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import phone1 from "../../../assets/image/home/looks/1-new.png";
import phone2 from "../../../assets/image/home/looks/2-new.png";
import phone3 from "../../../assets/image/home/looks/3-new.png";
import phone4 from "../../../assets/image/home/looks/4-new.png";

const Looks = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const images = [phone1, phone2, phone3, phone4];
  return (
    <StyledLooks>
      <Container>
        <Headline>{translation.home.looks.headline}</Headline>
        <Items>
          {images.map((image) => (
            <Item key={getUUID()}>
              <Image src={image} />
            </Item>
          ))}
        </Items>
      </Container>
    </StyledLooks>
  );
};

export default Looks;
