import styled from "styled-components";
import { Link } from "@think-internet/ui-components";

export const StyledFooter = styled.div`
  width: 100%;
  position: absolute;
  padding: 5px;
  bottom: 0px;
`;

export const Column = styled.div``;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: right;
  gap: 25px;
`;
