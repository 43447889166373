import { Container } from "@think-internet/ui-components";
import { Headline, StyledSequence, Image } from "./Sequence.Styled";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import image from "../../../assets/image/home/sequence/image.png";

const Sequence = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledSequence>
      <Container>
        <Headline>{translation.home.sequence.headline}</Headline>
        <Image src={image} />
      </Container>
    </StyledSequence>
  );
};

export default Sequence;
