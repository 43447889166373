import { StyledHome } from "./Home.Styled";
import Layout from "../Layout/Layout";
import Hero from "./Hero/Hero";
import Overview from "./Overview/Overview";
import Advantages from "./Advantages/Advantages";
import Usage from "./Usage/Usage";
import Looks from "./Looks/Looks";
import Happening from "./Happening/Happening";
import Sequence from "./Sequence/Sequence";
import Packages from "./Packages/Packages";
import Outro from "./Outro/Outro";

const Home = () => {
  return (
    <Layout authRequired={false}>
      <StyledHome>
        <Hero />
        <Overview />
        <Advantages />
        <Usage />
        <Looks />
        <Happening />
        <Sequence />
        <Packages />
        <Outro />
      </StyledHome>
    </Layout>
  );
};

export default Home;
