import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.font.size.md};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.font.size.sm};
  }
}

`;

export default GlobalStyles;
