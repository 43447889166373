import { StyledOutro, Logo, Content, Homepage, Copy } from "./Outro.Styled";
import logo from "../../../assets/image/home/outro/logo.png";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Footer from "../../Layout/Footer/Footer";
import CTA from "../../SubComponents/CTA/CTA";

const Outro = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledOutro>
      <Content>
        <Logo src={logo} />
        <CTA />
        <Copy>&copy;</Copy>
        <Homepage
          href={`https://${translation.home.outro.homepage}`}
          target="_blank"
        >
          {translation.home.outro.homepage}
        </Homepage>
      </Content>
      <Footer />
    </StyledOutro>
  );
};

export default Outro;
