import styled from "styled-components";

export const StyledSequence = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: 100px 0;
`;

export const Headline = styled.div`
  font-family: "bold";
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.font.size.xlg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

export const Image = styled.img`
  margin-top: 50px;
  width: 100%;
`;
